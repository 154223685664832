import React from "react";
import { useNavigate } from "react-router-dom";
import { cretateTestMemoDetails } from "./commonHandlerFunction/testMemoFunctionHandler";
import { encryptDataForURL } from "../../utills/useCryptoUtils";

const ListingActionButton = ({
  status,
  actions,
  user,
  moduleType,
  jrf_id,
  sampleInwardFormId,
  row,
  setIsRejectPopupOpen,
  setJRFCreationType,
  setIsPopupOpen,
  setIsOverlayLoader
}) => {
  const navigate = useNavigate();
  const actionBtnList = {
    jrf: [
      {
        btnStatus: "posted",
        label: "Verify Checklist",
        status: "checklist",
        btnshortName: "verifyCheckList",
      },
      {
        btnStatus: "accepted",
        label: "Sample Inward",
        status: "inward",
        btnshortName: "sampleInward",
      },
      {
        btnStatus: "completed",
        label: "Test Report",
        status: "testReport",
        btnshortName: "testReport",
      },
      {
        btnStatus: "rejected",
        label: "Update",
        status: "rejected",
        btnshortName: "Edit",
      },
    ],
    sampleinward: [
      {
        btnStatus: "inwarded",
        label: "Assignment",
        status: "assignment",
        btnshortName: "assignment",
      },
      {
        btnStatus: "assigning",
        label: "Assignment",
        status: "assignment",
        btnshortName: "assignment",
      },
      {
        btnStatus: "created",
        label: "Sample Inward",
        status: "inward",
        btnshortName: "sampleInward",
      },
      {
        btnStatus: "saved",
        label: "Sample Inward",
        status: "inward",
        btnshortName: "sampleInward",
      },
      {
        btnStatus: "assigned",
        label: "Create Test Memo",
        status: "testMemo",
        btnshortName: "testMemo",
      },
      {
        btnStatus: "certified",
        label: "Test Report",
        status: "certified",
        btnshortName: "testReport",
      },
    ],
    allotment: [
      {
        btnStatus: "pending",
        label: "Allot",
        status: "allot",
        btnshortName: "allot",
      },
      {
        btnStatus: "allotted",
        label: "Update",
        status: "allotted",
        btnshortName: "Edit",
      },
      {
        btnStatus: "allotted",
        label: "Verification",
        status: "verification",
        btnshortName: "verification",
      },
    ],
    testmemomain: [
      {
        btnStatus: "pending",
        label: "Verify Test Memo",
        status: "pending",
        btnshortName: "verifytestmemo",
      },
      {
        btnStatus: "posted",
        label: "Allot",
        status: "posted",
        btnshortName: "create-allot",
      },
      {
        btnStatus: "created",
        label: "Send to Lab",
        status: "sendToLab",
        btnshortName: "sendToLab",
      },
      {
        btnStatus: "results",
        label: "Verify Test Result",
        status: "results",
        btnshortName: "verifytestresult",
      },
      {
        btnStatus: "verified",
        label: "Test Report",
        status: "verified",
        btnshortName: "testmemoresult",
      },
      {
        btnStatus: "rejected",
        label: "Update",
        status: "rejected",
        btnshortName: "Edit",
      },
    ],
    sampleverification: [
      {
        btnStatus: "pending",
        label: "Verification",
        status: "verification",
        btnshortName: "verification",
      },
    ],
    sfm: [
      {
        btnStatus: "pending",
        label: "Result",
        status: "SFMResult",
        btnshortName: "SFMResult",
      },
      {
        btnStatus: "in-process",
        label: "Result",
        status: "SFMResult",
        btnshortName: "SFMResult",
      },
      {
        btnStatus: "rejected",
        label: "Update",
        status: "SFMUpdate",
        btnshortName: "SFMUpdate",
      },
    ],
    internalcertificate: [
      {
        btnStatus: "dtm-approved",
        label: "Verify",
        status: "verifyByTm",
        btnshortName: "verifyByTm",
      },
      {
        btnStatus: "pending",
        label: "Verify",
        status: "verifyByDTM",
        btnshortName: "verifyByDTM",
      },
      {
        btnStatus: "tm-approved",
        label: "Publish",
        status: "publishBtn",
        btnshortName: "publishBtn",
      },
      {
        btnStatus: "dtm-reject",
        label: "Update",
        status: "dtmReject",
        btnshortName: "dtmReject",
      },
      {
        btnStatus: "tm-reject",
        label: "Update",
        status: "tmReject",
        btnshortName: "tmReject",
      },
    ],
  };

  const rolesWiseBtnList = {
    jrf: {
      BU: ["testReport", "Edit"],
      LR: ["testReport", "verifyCheckList", "sampleInward"],
      TM: [],
      LC: [],
      DTM: [],
    },
    sampleinward: {
      BU: ["testReport"],
      LR: ["assignment", "testMemo", "testReport", "sendToLab", "sampleInward"],
      TM: ["verifytestmemo"],
      LC: [],
      DTM: [],
    },
    allotment: {
      BU: ["testReport"],
      LR: ["assignment", "testMemo", "certified"],
      TM: ["verifytestmemo", "Edit"],
      LC: ["verification"],
      DTM: [],
    },
    sampleverification: {
      BU: [],
      LR: [],
      TM: [],
      LC: ["verification"],
      DTM: [],
    },
    testmemomain: {
      BU: [],
      LR: ["sendToLab", "testmemoresult", "Edit"],
      TM: ["verifytestmemo", "create-allot", "verifytestresult"],
      LC: ["verification"],
      DTM: [],
    },
    sfm: {
      BU: [],
      LR: [],
      TM: [],
      LC: ["SFMResult","SFMUpdate"],
      DTM: [],
    },
    internalcertificate: {
      BU: [],
      LR: ["publishBtn", "dtmReject","tmReject"],
      TM: ["verifyByTm"],
      LC: [],
      DTM: ["verifyByDTM"],
    },
  };

  const getBtnListArray = () => {
    let btnFilteredData = [];
    btnFilteredData = actionBtnList[moduleType].filter((btn) => {
      return rolesWiseBtnList[moduleType]?.[user?.role].includes(
        btn.btnshortName
      );
    });
    return btnFilteredData;
  };
  //table Action buttion handler
  const handleOnclick = (action) => {
    if (moduleType === "sampleinward") {
      if (action.status === "testMemo") {
        cretateTestMemoDetails(sampleInwardFormId, action, navigate,setIsOverlayLoader);
      } else if (action?.status == "testReport") {
        navigate(action?.redirectUrl + "/" + encryptDataForURL(row["internal_certificate_id"]));
      } else {
        navigate(
          action?.redirectUrl +
            "?status=" +
            encryptDataForURL(action?.status) +
            "&sampleInwardId=" +
            encryptDataForURL(sampleInwardFormId) +
            "&id=" +
            encryptDataForURL(jrf_id)
        );
      }
    } else if (moduleType === "allotment") {
      navigate(
        action?.redirectUrl +
          "?status=" +
          encryptDataForURL(action?.status) +
          "&sampleAllotmentId=" +
          encryptDataForURL(row["sa_id"])
      );
    } else if (moduleType === "sampleverification") {
      navigate(
        action?.redirectUrl +
          "?status=" +
          encryptDataForURL(action?.status) +
          "&sampleVarificationId=" +
          encryptDataForURL(row["sv_id"])
      );
    } else if (moduleType === "testmemomain") {
      if (
        action.status === "create-allot" ||
        action.status === "sendToLab" ||
        action.status === "verifytestmemo"
      ) {
        navigate(
          action?.redirectUrl +
            "?status=" +
            encryptDataForURL(action?.status) +
            "&testMemoId=" +
            encryptDataForURL(row["tm_id"])
        );
      } else if (action.status == "verifytestresult") {
        navigate(
          action?.redirectUrl +
            "?status=" +
            encryptDataForURL(action?.status) +
            "&testMemoId=" +
            encryptDataForURL(row["tm_id"])
        );
      } else if (action.status == "testmemoresult") {
        navigate(
          action?.redirectUrl +
            "?status=" +
            encryptDataForURL(action?.status) +
            "&testMemoId=" +
            encryptDataForURL(row["tm_id"])
        );
      } else if (action.status == "Edit") {
        navigate(
          action?.redirectUrl +
            "?status=" +
            encryptDataForURL(action?.status) +
            "&testMemoId=" +
            encryptDataForURL(row["tm_id"])
        );
      }
    } else if (moduleType === "sfm") {
      navigate(
        action?.redirectUrl +
          "?status=" +
          encryptDataForURL(action?.status) +
          "&sfmid=" +
          encryptDataForURL(row["sfm_id"])
      );
    } else if (moduleType === "internalcertificate") {
      localStorage.setItem("icId", encryptDataForURL(row["ic_id"]));
      if (action?.status == "approveByDTM") {
        setIsPopupOpen(true);
        setJRFCreationType("approve");
      } else if (action?.status == "rejectByDTM") {
        setIsRejectPopupOpen(true);
      } else {
        navigate(
          action?.redirectUrl +
            "?status=" +
            encryptDataForURL(action?.status) +
            "&editId=" +
            encryptDataForURL(row["ic_id"])
        );
      }
    } else {
      if (action?.status == "testReport") {
        navigate(action?.redirectUrl + "/" + encryptDataForURL(row["internal_certificate_id"]));
      } else {
        navigate(
          action?.redirectUrl + "?status=" + encryptDataForURL(action?.status) + "&id=" + encryptDataForURL(jrf_id)
        );
      }
    }
  };
  const chkBtnExists = (action) => {
    const validConditions = getBtnListArray();
    const filterData = validConditions.filter((condition) => {
      if(action.isDupplicate){
        return (
          condition.btnStatus === status && condition.btnshortName === action?.status
        );
      }
      else{
        return (
          condition.btnStatus === status && condition.label === action?.label
        );
      }
    });
    return Boolean(filterData.length > 0);
  };
  return actions.map((action, actionIndex) => {
    const isValid = status && chkBtnExists(action);

    if (!isValid || action?.type === "icon") {
      return null;
    }
    return (
      <button
        type="button"
        key={"listing-action" + actionIndex}
        className="iconBtn"
        onClick={() => handleOnclick(action)}
      >
        {action?.label}
      </button>
    );
  });
};

export default ListingActionButton;
