import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import PropTypes from "prop-types";

import "react-phone-number-input/style.css";

const InputPhone = ({ field }) => {
  const {
    name,
    label,
    value,
    onChange,
    required,
    error,
    readOnly,
    tooltip,
    pattern,
    actionClicked,
  } = field;



  const [errorMsg, setErrorMsg] = useState(false);
  const [mobileValue, setmobileValue] = useState(value || " ");
  useEffect(() => {
    const regex = new RegExp(pattern);
    if (mobileValue !== "") {
      if (regex.test(mobileValue)) {
        setErrorMsg(false);
      } else {
        setErrorMsg(true);
      }
    } else {
      setErrorMsg(false);
    }
  }, [mobileValue]);

  useEffect(() => {
   
    onChange(mobileValue);
  }, [mobileValue]);

  return (
    <div className="form-group my-2">
      {label && (
        <label htmlFor={name} style={{ width: `${18}%` }}>
          {label}
          <span className="required_mark"> {required ? ` *` : null}</span>
        </label>
      )}
      <div className="w-75 d-inline-block mx-2">
      
        <PhoneInput
          placeholder="Enter phone number"
          id={name}
          name={name}
          defaultCountry="IN"
          value={mobileValue}
          onChange={setmobileValue}
          required={required}
          className="form-control rounded-2"
          readOnly={readOnly}
          title={tooltip}
        />
        
        {error && actionClicked && <p className="text-danger">{error}</p>}
      </div>
    </div>
  );
};

InputPhone.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    error: PropTypes.string,
    readOnly: PropTypes.bool,
    tooltip: PropTypes.string,
    pattern: PropTypes.string,
    actionClicked: PropTypes.func
  })
};

export default InputPhone;
