import Carousel from "react-bootstrap/Carousel";

import slider1 from "../assets/images/logos/slider1.1.png";
import slider5 from "../assets/images/logos/slider5.png";
import slider6 from "../assets/images/logos/slider6.png";
import slider7 from "../assets/images/logos/slider7.png";

const Sliders = () => {
  const slides = [slider1, slider5, slider6, slider7];
  return (
    <Carousel fade controls={false} slide={false}>
      {slides.map((slide, slideIndex) => (
        <Carousel.Item key={"Slide" + slideIndex}>
          <img src={slide} alt={slide} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Sliders;
